import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";

import SidebarMenu from "../Widgets/SideBar Menu/SidebarMenu";
import Interview from "./Interview";
import Practical from "./Practical";

const Locations = () => {
  const [InterviewTabActive, setInterviewTabActive] = useState(true);
  const [PracticalTabActive, setPracticalTabActive] = useState(false);

  useEffect(() => {
    const tabs = document.querySelectorAll(".nav_top_bar");

    tabs.forEach((tab) => {
      const tabId = tab.getAttribute("id");

      if (tabId === "tabs_view_edit") {
        tab.setAttribute(
          "aria-selected",
          InterviewTabActive ? "true" : "false"
        );
      } else if (tabId === "tabs_documents") {
        tab.setAttribute(
          "aria-selected",
          PracticalTabActive ? "true" : "false"
        );
      }
    });
  }, [InterviewTabActive, PracticalTabActive]);

  return (
    <>
      <div className="main_desktop">
        <hr className="mb-2 thick-hr" />

        <div className="main-wrapper">
          <div className="sub-wrapper-sidebar">
            <SidebarMenu />
          </div>

          <div className="sub-wrapper-main">
            <div className="main-heading-title">
              <h3 className="heading-admin-title text-color-green">
                Locations
              </h3>
            </div>
            <div className="main-body-area mb-5">
              <Card className="shadow">
                {/* <section className="main-body-area shadow mb-5" style={{marginTop:"20px"}}> */}
                <div className="card card-primary card-tabs">
                  <div className="card-header p-0 border-bottom-0">
                    <ul className="nav nav-tabs">
                      <li
                        className="nav-item"
                        style={{ marginTop: "0px", cursor: "pointer" }}
                      >
                        <a
                          className={`nav-link nav_inactive_tab_btn ${
                            InterviewTabActive ? "nav_active_tab_btn" : ""
                            // InterviewTabActive ? "bg-green text-yellow btn-" : ""
                          }`}
                          id="tabs_view_edit"
                          // href="#"
                          role="tab"
                          aria-controls="tabs_view_edit"
                          aria-selected={InterviewTabActive ? "true" : "false"}
                          onClick={() => {
                            setInterviewTabActive(true);
                            setPracticalTabActive(false);
                          }}
                        >
                          <h6 className="h6">Interview</h6>
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className={`nav-link nav_inactive_tab_btn ${
                            PracticalTabActive ? "nav_active_tab_btn" : ""
                          }`}
                          id="tabs_documents"
                          // href="#"
                          role="tab"
                          aria-controls="tabs_documents"
                          aria-selected={PracticalTabActive ? "true" : "false"}
                          onClick={() => {
                            setPracticalTabActive(true);
                            setInterviewTabActive(false);
                          }}
                        >
                          <h6 className="h6">Practical</h6>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div>
                  {InterviewTabActive && <Interview />}
                  {PracticalTabActive && <Practical />}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Locations;
