import React, { useState } from "react";
import SidebarMenu from "../Widgets/SideBar Menu/SidebarMenu";
import AQATO from "./Aqato/Aqato";
import ATTC from "./Attc/Attc";

const StaffManagement = () => {
  const [AtatoTab, setAtatoTab] = useState(true);
  const [AttcTab, setAttcTab] = useState(false);

  return (
    <>
      <div className="main_desktop">
        <hr className="mb-2 thick-hr" />

        <div className="main-wrapper">
          <div className="sub-wrapper-sidebar">
            <SidebarMenu />
          </div>

          <div className="sub-wrapper-main">
            <div className="main-heading-title">
              <h3 className="heading-admin-title text-color-green">
                Staff Management
              </h3>
            </div>

            <div className="card card-primary card-tabs">
              <div className="card-header p-0 border-bottom-0">
                <ul className="nav nav-tabs">
                  <li
                    className="nav-item"
                    style={{ marginTop: "0px", cursor: "pointer" }}
                  >
                    <a
                      className={`nav-link nav_inactive_tab_btn ${AtatoTab ? "nav_active_tab_btn" : ""
                        }`}
                      id="tabs_view_edit"
                      // href="#"
                      role="tab"
                      aria-controls="tabs_view_edit"
                      aria-selected={AtatoTab ? "true" : "false"}
                      onClick={() => {
                        setAtatoTab(true);
                        setAttcTab(false);
                      }}
                    >
                      <h6 className="h6">AQATO</h6>
                    </a>
                  </li>
                  <li
                    className="nav-item custom_fix_top_margin"
                    style={{ cursor: "pointer" }}
                  >
                    <a
                      className={`nav-link nav_inactive_tab_btn ${AttcTab ? "nav_active_tab_btn" : ""
                        }`}
                      id="tabs_documents"
                      // href="#"
                      role="tab"
                      aria-controls="tabs_documents"
                      aria-selected={AttcTab ? "true" : "false"}
                      onClick={() => {
                        setAtatoTab(false);
                        setAttcTab(true);
                      }}
                    >
                      <h6 className="h6">ATTC</h6>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {AtatoTab && <AQATO />}
            {AttcTab && <ATTC />}
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffManagement;
