import React, { useState, useEffect, useRef } from "react";
import { Accordion, Col, Row, Table } from "react-bootstrap";
import {
  postSaveSingleFieldOfViewUpdateOfApplicationManager,
  postUpdateStageStatusOfDataViewUpdateOfApplicationManager,
} from "../../../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Widgets/Loader/Loader";
import VerificationModal from "./VerificationModal/VerificationModal";

const Stage_3_R = ({ ApiPageData, pointerID, getAllDataOfViewUpdateFunction ,getAllDataOfViewUpdateFunctionAfterAutoSave}) => {
  const stage = "stage_3_R";
  const [isSelectionMade, setIsSelectionMade] = useState(false);

  const [OnlineSelected, setOnlineSelected] = useState(false);


  const [loading, setLoading] = useState(false);

  const [extraData, setExtraData] = useState({});
  const [isExtraDataEditing, setIsExtraDataEditing] = useState({});

  const [BigTextAreaData, setBigTextAreaData] = useState({});
  const [isBigTextAreaEditing, setIsBigTextAreaEditing] = useState({});

  const [teamMember, setTeamMember] = useState({});
  const [isTeamMemberEditing, setIsTeamMemberEditing] = useState({});

  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatusValue, setSelectedStatusValue] = useState("");


  const [isFileChoosen, setIsFileChoosen] = useState({});

  
  const [isFileEditing, setIsFileEditing] = useState({});
  const [fileState, setFileState] = useState({});
  const [fileErrors, setFileErrors] = useState({});
  const fileInputRefs = useRef({});

  const [currentFields, setCurrentFields] = useState([]);



  useEffect(() => {
    if (ApiPageData?.offline_location_id === "9") {
      setOnlineSelected(true);
    } else setOnlineSelected(false);

    setSelectedStatusValue(ApiPageData?.status);
    setIsSelectionMade(false);

  }, [ApiPageData]);




  useEffect(() => {
    if (ApiPageData) {
      // Setting initial data based on selected options
      const initialBigTextAreaData = {};
      const initialExtraData = {};
      const initialTeamMember = {};
      const initialEditingState = {};
  
      ApiPageData.selected_options.fields.forEach((field) => {
        if (field.type === "textarea") {
          initialBigTextAreaData[field.param] = field.db_value;
          initialEditingState[field.param] = false; 
        } else if (field.type === "select" || field.type === "time_zone_select" || field.type === "special_select") {
          initialTeamMember[field.param] = field.db_value;
          initialEditingState[field.param] = false;
        } else if (["text", "date", "number"].includes(field.type)) {
          initialExtraData[field.param] = field.db_value;
          initialEditingState[field.param] = false; 
        } else if (field.type === "file") {
          initialEditingState[field.param] = false;
        }
        // Add other field types as needed
      });
  
      // Update the state
      setBigTextAreaData(initialBigTextAreaData);
      setExtraData(initialExtraData);
      setTeamMember(initialTeamMember);
      setIsExtraDataEditing(initialEditingState);
      setIsBigTextAreaEditing(initialEditingState);
      setIsTeamMemberEditing(initialEditingState);
      setIsFileEditing(initialEditingState);
    }
  }, [ApiPageData]);

  
  useEffect(() => {
    if (ApiPageData) {
      setStatusOptions(ApiPageData?.options || []);
      //   setSelectedStatusValue(ApiPageData?.status || "");
      const currentStatusOption = ApiPageData?.options?.find(
        (opt) => opt?.option === ApiPageData?.status
      );
      setCurrentFields(currentStatusOption?.fields || []);
    }
  }, [ApiPageData]);

  useEffect(() => {
    const currentStatusOption = statusOptions.find(
      (opt) => opt?.option === selectedStatusValue
    );
    setCurrentFields(currentStatusOption?.fields || []);
  }, [selectedStatusValue, statusOptions]);

  useEffect(() => {
    setSelectedStatusValue(ApiPageData?.status);
  }, [ApiPageData]);



  
  const callGetFunction = async () => {

    getAllDataOfViewUpdateFunctionAfterAutoSave();

  };



  const saveSingleFileDataFunction = async (formData) => {
    try {
      setLoading(true);

      const response =
        await postSaveSingleFieldOfViewUpdateOfApplicationManager(formData);
        // callGetFunction();
      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        toast.success(
          response?.data?.response?.success_msg || "Saved Successfully."
        );

           getAllDataOfViewUpdateFunction(pointerID);


      } else {
        setLoading(false);
        console.error(
          "Error saving single data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Error in saving");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error saving single data:", error);
    }

  };


  


  const saveSingleDataFunction = async (formData) => {
    try {
      setLoading(true);

      const response =
        await postSaveSingleFieldOfViewUpdateOfApplicationManager(formData);
        // callGetFunction();
      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        toast.success(
          response?.data?.response?.success_msg || "Saved Successfully."
        );

           // getAllDataOfViewUpdateFunction(pointerID);

        
          // if (isSelectionMade && statusOptions.some((opt) => opt?.option === selectedStatusValue)) {
          //   getAllDataOfViewUpdateFunction(pointerID);
          // }


      } else {
        setLoading(false);
        console.error(
          "Error saving single data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Error in saving");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error saving single data:", error);
    }

  };

  const autoSaveSingleDataFunction = async (formData) => {
    try {
      // setLoading(true);

      const response =
        await postSaveSingleFieldOfViewUpdateOfApplicationManager(formData);

      // setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        // toast.success(
        //   response?.data?.response?.success_msg || "Saved Successfully."
        // );


        // getAllDataOfViewUpdateFunctionAfterAutoSave(pointerID);
        // getAllDataOfViewUpdateFunction(pointerID);
        
        //  console.log("saved")

      } else {
        // setLoading(false);
        console.error(
          "Error saving single data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        // toast.error(response?.data?.response?.error_msg || "Error in saving");
      }
    } catch (error) {
      // setLoading(false);
      console.error("Error saving single data:", error);
    }
  };

  const UpdateStageStatusFunction = async (formData) => {
    try {
      setLoading(true);

      const response =
        await postUpdateStageStatusOfDataViewUpdateOfApplicationManager(
          formData
        );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        // setSelectedStatusValue("");
        toast.success(
          response?.data?.response?.success_msg || "Stage Updated Successfully."
        );
        getAllDataOfViewUpdateFunction(pointerID);
        setCancelRequestShow(false);
        setIsSelectionMade(false);
      } else {
        setLoading(false);
        console.error(
          "Error updating stage status data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Failed to update.");
      }
    } catch (error) {
      // setLoading(false);
      console.error("Error updating stage status data:", error);
    }
  };

  const handleChangeStatus = (event) => {
    setIsSelectionMade(true);
    const newStatus = event.target.value;

    // Clear all states when status changes
    if (newStatus !== selectedStatusValue) {
      setExtraData({});
      setFileState({});
      setFileErrors({});
      setTeamMember({});
      setIsFileEditing({});
      setIsExtraDataEditing({});
      setIsTeamMemberEditing({});
      setBigTextAreaData({});
      setIsBigTextAreaEditing({});
    }

    setSelectedStatusValue(newStatus);
  };
  const handleUpdate = () => {
    if (selectedStatusValue === "") {
      toast.warning("Please select status");
      return;
    }

    const formData = new FormData();
    formData.append("status", selectedStatusValue);
    formData.append("stage", stage);
    formData.append("pointer_id", pointerID);
    formData.append("id", ApiPageData?.id);

    [
      ...Object.entries(extraData),
      ...Object.entries(BigTextAreaData),
      ...Object.entries(fileState),
      ...Object.entries(teamMember),
    ].forEach(([param, value]) => {
      if (currentFields.some((field) => field.param === param)) {
        if (value) {
          formData.append(param, value);
        }
      }
    });

    UpdateStageStatusFunction(formData);
  };





// Common debounce function
const debounce = (callback, delay = 2000) => {

  let timeout;
  
  // Return a function that handles debouncing
  return (...args) => {
    // Clear the previous timeout
    clearTimeout(timeout);

    // Set a new timeout to execute the callback after the delay
    timeout = setTimeout(() => {
      callback(...args); // Call the provided callback with arguments
    }, delay);
  };
};

















  // const handleChangeAQATO_TeamMember = (e, param) => {
  //   setTeamMember((prevData) => ({
  //     ...prevData,
  //     [param]: e.target.value,
  //   }));
  //   setIsTeamMemberEditing((prevEditing) => ({
  //     ...prevEditing,
  //     [param]: true,
  //   }));

  //   const formData = new FormData();
  //   formData.append("param", param);
  //   formData.append("value", e.target.value);
  //   formData.append("status", selectedStatusValue);
  //   formData.append("stage", stage);
  //   formData.append("pointer_id", pointerID);

  //   autoSaveSingleDataFunction(formData);
  // };

  // const toggleTeamMemberEditing = (param) => {
  //   setIsTeamMemberEditing((prevEditing) => ({
  //     ...prevEditing,
  //     [param]: !prevEditing[param],
  //   }));

  //   if (isTeamMemberEditing[param]) {
  //     saveTeamMember(param);
  //   }
  // };

  // const saveTeamMember = (param) => {
  //   // console.log(
  //   //   `Saving team member for field: ${param}, Value:`,
  //   //   teamMember[param]
  //   // );

  //   const formData = new FormData();

  //   formData.append("param", param);
  //   formData.append("value", teamMember[param]);

  //   formData.append("status", selectedStatusValue);
  //   formData.append("stage", stage);
  //   formData.append("pointer_id", pointerID);

  //   saveSingleDataFunction(formData);
  //   setTeamMember((prevState) => ({ ...prevState, [param]: "" }));
  // };





// Handle changes in the team member field and trigger autosave
const handleChangeAQATO_TeamMember = (e, param) => {
  const newValue = e.target.value;

  // Update the teamMember state with the new value
  setTeamMember((prevData) => ({
    ...prevData,
    [param]: newValue,
  }));

  // Find the current field by matching the param
  const currentField = currentFields.find((field) => field.param === param);

  // Show the save button if the new value differs from the db_value
  setIsTeamMemberEditing((prevEditing) => ({
    ...prevEditing,
    [param]: newValue !== currentField?.db_value, // Show if values differ
  }));


  // getAllDataOfViewUpdateFunctionAfterAutoSave(pointerID);



  // Prepare form data for autosave
  const formData = new FormData();
  formData.append("param", param);
  formData.append("value", newValue);
  formData.append("status", selectedStatusValue);
  formData.append("stage", stage);
  formData.append("pointer_id", pointerID);



  autoSaveSingleDataFunction(formData);

  // const debouncedAutoSave = debounce(autoSaveSingleDataFunction, 2000);
  // debouncedAutoSave(formData);


  // getAllDataOfViewUpdateFunctionAfterAutoSave(pointerID);

};

// Toggle save button and save the team member data if needed
const toggleTeamMemberEditing = (param) => {
  // If the field is being edited, save the data
  if (isTeamMemberEditing[param]) {
    saveTeamMember(param);
  }

  // Hide the save button after saving or if not editing
  setIsTeamMemberEditing((prevEditing) => ({
    ...prevEditing,
    [param]: false,
  }));
};

// Save the team member data to the backend
const saveTeamMember = (param) => {
  // Log the data being saved for debugging
  console.log(`Saving team member data for field: ${param}, Value:`, teamMember[param]);

  // Find the current field by matching the param
  const currentField = currentFields.find((field) => field.param === param);

  // Prepare form data for saving
  const formData = new FormData();
  formData.append("param", param);
  formData.append("value", teamMember[param]);
  formData.append("status", selectedStatusValue);
  formData.append("stage", stage);
  formData.append("pointer_id", pointerID);

  // Call the save function
  saveSingleDataFunction(formData);

  // Optionally reset the value to db_value after saving
  setTeamMember((prevState) => ({
    ...prevState,
    [param]: currentField?.db_value, // Reset to db_value after save
  }));
};














































// Modify handleFileChange
const handleFileChange = (e, param) => {
  const file = e.target.files[0];

  if (file && file.type !== "application/pdf") {
    setIsFileChoosen((prev) => ({ ...prev, [param]: false }));
    setFileErrors((prevErrors) => ({
      ...prevErrors,
      [param]: "Please upload a PDF file.",
    }));
    toast.error("Please upload a PDF file.");
    setFileState((prevState) => ({ ...prevState, [param]: null }));
    fileInputRefs.current[param].value = null;
  } else {
    setFileErrors((prevErrors) => ({
      ...prevErrors,
      [param]: "",
    }));
    setFileState((prevState) => ({ ...prevState, [param]: file }));
    setIsFileChoosen((prev) => ({ ...prev, [param]: true }));
    setIsFileEditing((prevEditing) => ({
      ...prevEditing,
      [param]: true,
    }));
  }
};

  const toggleFileEditing = (param) => {
    const isEnteringEditMode = !isFileEditing[param];
    setIsFileEditing((prevEditing) => ({
      ...prevEditing,
      [param]: isEnteringEditMode,
    }));

    if (!isEnteringEditMode) {
      saveFile(param);
    }
  };

  const saveFile = (param) => {
    if (!fileState[param]) {
      toast.error("Please select a PDF file.");
      return;
    }

    console.log(`Saving file for field: ${param}, File:`, fileState[param]);

    const formData = new FormData();
    formData.append("param", param);
    formData.append("value", fileState[param]);
    formData.append("status", selectedStatusValue);
    formData.append("stage", stage);
    formData.append("pointer_id", pointerID);

    // saveSingleDataFunction(formData);
    saveSingleFileDataFunction(formData);

    

    setFileState((prevState) => ({ ...prevState, [param]: null }));
    setFileState((prevState) => ({ ...prevState, [param]: null }));
    fileInputRefs.current[param].value = null;
  };

























  // const handleExtraDataChange = (e, param) => {
  //   setExtraData((prevData) => ({
  //     ...prevData,
  //     [param]: e.target.value,
  //   }));

  //   const formData = new FormData();
  //   formData.append("param", param);
  //   formData.append("value", e.target.value);
  //   formData.append("status", selectedStatusValue);
  //   formData.append("stage", stage);
  //   formData.append("pointer_id", pointerID);

  //   autoSaveSingleDataFunction(formData);
  // };

  // const toggleExtraDataEditing = (param) => {
  //   setIsExtraDataEditing((prevEditing) => ({
  //     ...prevEditing,
  //     [param]: !prevEditing[param],
  //   }));

  //   if (isExtraDataEditing[param]) {
  //     saveExtraData(param);
  //   }
  // };

  // const saveExtraData = (param) => {
  //   console.log(
  //     `Saving extra data for field: ${param}, Value:`,
  //     extraData[param]
  //   );

  //   const formData = new FormData();

  //   formData.append("param", param);
  //   formData.append("value", extraData[param]);

  //   formData.append("status", selectedStatusValue);
  //   formData.append("stage", stage);
  //   formData.append("pointer_id", pointerID);

  //   saveSingleDataFunction(formData);
  //   setExtraData((prevState) => ({ ...prevState, [param]: "" }));
  // };







  // Handle changes in the extra data field and trigger autosave
const handleExtraDataChange = (e, param) => {
  const newValue = e.target.value;

  // Update the extraData state with the new value
  setExtraData((prevData) => ({
    ...prevData,
    [param]: newValue,
  }));

  // Find the current field by matching the param
  const currentField = currentFields.find((field) => field.param === param);

  // Show the save button if the new value differs from the db_value
  setIsExtraDataEditing((prevEditing) => ({
    ...prevEditing,
    [param]: newValue !== currentField?.db_value, // Show if values differ
  }));

  // getAllDataOfViewUpdateFunctionAfterAutoSave(pointerID);

  // Prepare form data for autosave
  const formData = new FormData();
  formData.append("param", param);
  formData.append("value", newValue);
  formData.append("status", selectedStatusValue);
  formData.append("stage", stage);
  formData.append("pointer_id", pointerID);


  autoSaveSingleDataFunction(formData);

  // const debouncedAutoSave = debounce(autoSaveSingleDataFunction, 2000);
  // debouncedAutoSave(formData);

  // getAllDataOfViewUpdateFunctionAfterAutoSave(pointerID);

};

// Toggle the save button and save the extra data if needed
const toggleExtraDataEditing = (param) => {
  // If the current field is being edited, save the data
  if (isExtraDataEditing[param]) {
    saveExtraData(param);
  }

  // Hide the save button after saving or if not editing
  setIsExtraDataEditing((prevEditing) => ({
    ...prevEditing,
    [param]: false,
  }));
};

// Save the extra data to the backend
const saveExtraData = (param) => {
  // Log the data being saved for debugging
  console.log(`Saving extra data for field: ${param}, Value:`, extraData[param]);

  // Find the current field by matching the param
  const currentField = currentFields.find((field) => field.param === param);

  // Prepare form data for saving
  const formData = new FormData();
  formData.append("param", param);
  formData.append("value", extraData[param]);
  formData.append("status", selectedStatusValue);
  formData.append("stage", stage);
  formData.append("pointer_id", pointerID);

  // Call the save function
  saveSingleDataFunction(formData);

  // Optionally reset the value to db_value after saving
  setExtraData((prevState) => ({
    ...prevState,
    [param]: currentField?.db_value, // Reset to db_value after save
  }));
};





































  // const handleBigTextAreaDataChange = (e, param) => {
  //   setBigTextAreaData((prevData) => ({
  //     ...prevData,
  //     [param]: e.target.value,
  //   }));

  //   const formData = new FormData();
  //   formData.append("param", param);
  //   formData.append("value", e.target.value);
  //   formData.append("status", selectedStatusValue);
  //   formData.append("stage", stage);
  //   formData.append("pointer_id", pointerID);

  //   autoSaveSingleDataFunction(formData);
  // };

  // const toggleBigTextAreaDataEditing = (param) => {
  //   setIsBigTextAreaEditing((prevEditing) => ({
  //     ...prevEditing,
  //     [param]: !prevEditing[param],
  //   }));

  //   if (isBigTextAreaEditing[param]) {
  //     saveBigTextAreaData(param);
  //   }
  // };

  // const saveBigTextAreaData = (param) => {
  //   console.log(
  //     `Saving big text area data for field: ${param}, Value:`,
  //     BigTextAreaData[param]
  //   );
  //   const formData = new FormData();

  //   formData.append("param", param);
  //   formData.append("value", BigTextAreaData[param]);

  //   formData.append("status", selectedStatusValue);
  //   formData.append("stage", stage);
  //   formData.append("pointer_id", pointerID);

  //   saveSingleDataFunction(formData);
  //   setBigTextAreaData((prevState) => ({ ...prevState, [param]: "" }));
  // };





// Handle changes in the text area and trigger autosave
const handleBigTextAreaDataChange = (e, param) => {
  const newValue = e.target.value;

  // Update the BigTextAreaData state with the new value
  setBigTextAreaData((prevData) => ({
    ...prevData,
    [param]: newValue,
  }));

  // Find the current field by matching the param
  const currentField = currentFields.find((field) => field.param === param);

  // Show save button when the current value is different from db_value
  setIsBigTextAreaEditing((prevEditing) => ({
    ...prevEditing,
    [param]: newValue !== currentField?.db_value, 
  }));

  // getAllDataOfViewUpdateFunctionAfterAutoSave(pointerID);

  // Prepare the form data for autosave
  const formData = new FormData();
  formData.append("param", param);
  formData.append("value", newValue);
  formData.append("status", selectedStatusValue);
  formData.append("stage", stage);
  formData.append("pointer_id", pointerID);


  autoSaveSingleDataFunction(formData);

  // const debouncedAutoSave = debounce(autoSaveSingleDataFunction, 2000);
  // debouncedAutoSave(formData);

  // getAllDataOfViewUpdateFunctionAfterAutoSave(pointerID);

};

// Toggle the save button and save the data
const toggleBigTextAreaDataEditing = (param) => {
  // If the current field is being edited, save the data
  if (isBigTextAreaEditing[param]) {
    saveBigTextAreaData(param);
  }

  // Hide the save button after saving the data
  setIsBigTextAreaEditing((prevEditing) => ({
    ...prevEditing,
    [param]: false,
  }));

};

// Save the data to the backend
const saveBigTextAreaData = (param) => {
  // Log the data being saved
  console.log(
    `Saving big text area data for field: ${param}, Value:`,
    BigTextAreaData[param]
  );

  // Find the current field by matching the param
  const currentField = currentFields.find((field) => field.param === param);

  // Prepare form data to save
  const formData = new FormData();
  formData.append("param", param);
  formData.append("value", BigTextAreaData[param]);
  formData.append("status", selectedStatusValue);
  formData.append("stage", stage);
  formData.append("pointer_id", pointerID);

  // Call the save function
  saveSingleDataFunction(formData);

  // Optionally reset the value or ensure it matches db_value after saving
  setBigTextAreaData((prevState) => ({
    ...prevState,
    [param]: currentField?.db_value, // Reset to db_value after save
  }));
};
















  const [showCancelRequestModal, setCancelRequestShow] = useState(false);
  const handleCancelRequestClose = () => setCancelRequestShow(false);
  const handleCancelRequestShow = () => {
    setCancelRequestShow(true);
  };








    
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  // Filter the field for time zone select
  const fieldForZoneNames = ApiPageData?.selected_options?.fields.find(
    (field) => field?.type === "time_zone_select"
  );

  // Filter the options within the time zone select field based on search term
  const filteredOptions = fieldForZoneNames?.options?.filter((tmz) =>
    tmz.zone_name.toLowerCase().includes(searchTerm.toLowerCase())
  );




  return (
    <>
      <Accordion.Item eventKey="5">
        <Accordion.Header className="accordion_header">
        Stage 3 - Technical Interview (Reassessment)
        </Accordion.Header>

        {loading && <Loader />}

        <Accordion.Body>
          <Table striped>
            <tbody>
              {ApiPageData?.status && (
                <tr style={{ cursor: "default" }}>
                  <td>
                    <b style={{ color: "#055837" }}>Current Status</b>
                  </td>
                  <td className="w-25">
                    <b style={{ color: "#055837" }}>{ApiPageData?.status}</b>
                  </td>
                </tr>
              )}

              {ApiPageData?.submitted_date !== "" && (
                <tr>
                  <td width="30%">Date Submitted</td>
                  <td className="w-25">{ApiPageData?.submitted_date}</td>
                </tr>
              )}

              {ApiPageData?.lodged_date !== "" && (
                <tr>
                  <td width="30%">Date Loged</td>
                  <td className="w-25">{ApiPageData?.lodged_date}</td>
                </tr>
              )}

              {ApiPageData?.in_progress_date !== "" && (
                <tr>
                  <td width="30%">Date In Progress</td>
                  <td className="w-25">{ApiPageData?.in_progress_date}</td>
                </tr>
              )}

              
{ApiPageData?.scheduled_date !== "" && (
                <tr>
                  <td width="30%">Date Scheduled</td>
                  <td className="w-25">{ApiPageData?.scheduled_date}</td>
                </tr>
              )}
                       {ApiPageData?.interview__date !== "" && (
                <tr>
                  <td width="30%">Interview Date</td>
                  <td className="w-25">{ApiPageData?.interview__date}</td>
                </tr>
              )}




              {ApiPageData?.approved_date !== "" && (
                <tr>
                  <td width="30%">Date Approved</td>
                  <td className="w-25">{ApiPageData?.approved_date}</td>
                </tr>
              )}

              {ApiPageData?.omitted_date !== "" && (
                <tr>
                  <td width="30%"> Date Omitted</td>
                  <td className="w-25">{ApiPageData?.omitted_date}</td>
                </tr>
              )}

              {ApiPageData?.withdraw_date !== "" && (
                <tr>
                  <td width="30%"> Date Withdraw</td>
                  <td className="w-25">{ApiPageData?.withdraw_date}</td>
                </tr>
              )}

              {ApiPageData?.declined_date !== "" && (
                <tr>
                  <td width="30%"> Date Declined</td>
                  <td className="w-25">{ApiPageData?.declined_date}</td>
                </tr>
              )}

              {/* {ApiPageData?.archive_date !== "" && (
                <tr>
                  <td width="30%"> Date Archive</td>
                  <td className="w-25">{ApiPageData?.archive_date}</td>
                </tr>
              )} */}

{ ApiPageData?.expiry_custom_date?.label !== "Date Closed" && ApiPageData?.expiry_custom_date &&
                !Array.isArray(ApiPageData.expiry_custom_date) &&
                ApiPageData?.expiry_custom_date?.label !== "" &&
                ApiPageData?.expiry_custom_date?.value !== "" && (
                  <tr>
                    <td width="30%">
                      {ApiPageData?.expiry_custom_date?.label}
                    </td>
                    <td className="w-25">
                      {ApiPageData?.expiry_custom_date?.value}
                    </td>
                  </tr>
                )}

              {/* {ApiPageData?.expiry_date !== "" && (
                <tr>
                  <td width="30%">Expiry Date</td>
                  <td className="w-25">{ApiPageData?.expiry_date}</td>
                </tr>
              )}

              {ApiPageData?.closure_date !== "" && (
                <tr>
                  <td width="30%">Closure Date</td>
                  <td className="w-25">{ApiPageData?.closure_date}</td>
                </tr>
              )} */}

              {ApiPageData?.date_reinstate !== "" && (
                <tr>
                  <td width="30%">Date Reinstate</td>
                  <td className="w-25">{ApiPageData?.date_reinstate}</td>
                </tr>
              )}

              {ApiPageData?.lodged_date_additional !== "" && (
                <tr>
                  <td width="30%"> Date Additional Info Lodged</td>
                  <td className="w-25">
                    {ApiPageData?.lodged_date_additional}
                  </td>
                </tr>
              )}

              {ApiPageData?.lodged_comment !== "" && (
                <tr>
                  <td width="30%">Loged Comment</td>
                  <td className="w-25">{ApiPageData?.lodged_comment}</td>
                </tr>
              )}

              {ApiPageData?.approved_comment !== "" && (
                <tr>
                  <td width="30%">Approved Comment</td>
                  <td className="w-25">{ApiPageData?.approved_comment}</td>
                </tr>
              )}

              {ApiPageData?.declined_reason !== "" && (
                <tr>
                  <td width="30%">Declined Reason</td>
                  <td className="w-25">{ApiPageData?.declined_reason}</td>
                </tr>
              )}




{ ApiPageData?.expiry_custom_date?.label === "Date Closed" && ApiPageData?.expiry_custom_date &&
                !Array.isArray(ApiPageData.expiry_custom_date) &&
                ApiPageData?.expiry_custom_date?.label !== "" &&
                ApiPageData?.expiry_custom_date?.value !== "" && (
                  <tr>
                    <td width="30%">
                      {ApiPageData?.expiry_custom_date?.label}
                    </td>
                    <td className="w-25">
                      {ApiPageData?.expiry_custom_date?.value}
                    </td>
                  </tr>
                )}


              <tr>
                <td width="30%">Change Status</td>
                <td className="w-25">
                  <Row>
                    <Col lg={8}>
                    <select
  className="form-select"
  value={
    isSelectionMade && statusOptions.some((opt) => opt?.option === selectedStatusValue)
      ? selectedStatusValue
      : ""
  }
  onChange={(e) => handleChangeStatus(e)}
  style={{ cursor: "pointer" }}
>
  <option value="" disabled>
    Select Status
  </option>
  {statusOptions.map((opt) => (
    <option
      key={opt?.option}
      value={opt?.option}
      disabled={opt?.is_enabled === 0}
    >
      {opt?.option}
    </option>
  ))}
</select>

                    </Col>
                  </Row>
                </td>
              </tr>



























































              {
                ApiPageData?.selected_options?.fields.length > 0
                  ? ApiPageData?.selected_options?.fields.map((field) => (
                      <React.Fragment key={field?.param}>




                        {field?.type === "select" && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>
                                <Col lg={8}>
                                  <select
                                    value={
                                      teamMember[field?.param] ??
                                      field?.db_value 
                                    }
                                    onChange={(e) =>
                                      handleChangeAQATO_TeamMember(
                                        e,
                                        field?.param
                                      )
                                    }
                                    className="form-select"
                                    // disabled={
                                    //   !isTeamMemberEditing[field?.param]
                                    // }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <option value="" disabled>
                                      Select Team Member
                                    </option>
                                    {field.options?.map((member) => (
                                      <option
                                        key={member.id}
                                        value={member.id}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {member.fullname}
                                      </option>
                                    ))}
                                  </select>
                                </Col>

                                <Col>
                                  {/* <button
                                    className="btn btn-sm btn_green_yellow"
                                    onClick={() =>
                                      toggleTeamMemberEditing(field?.param)
                                    }
                                  >
                                    <i
                                      className={
                                        isTeamMemberEditing[field?.param]
                                          ? "bi bi-check-lg"
                                          : "bi bi-pencil-square"
                                      }
                                    ></i>
                                  </button> */}

{/* {(teamMember[field?.param] !== field?.db_value &&
                isTeamMemberEditing[field?.param]) && (
                <button
                  className="btn btn-sm btn_green_yellow"
                  title="Save"
                  onClick={() => toggleTeamMemberEditing(field?.param)}
                >
                  <i className="bi bi-check-lg"></i>
                </button>
              )} */}

                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )}









{field?.type === "special_select" && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>
                                <Col lg={8}>
                                  <select
                                    value={
                                      teamMember[field?.param] ??
                                      field?.db_value 
                                    }
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        if (selectedValue === "9") {
                                          setOnlineSelected(true);
                                        } else {
                                          setOnlineSelected(false);
                                        }
  
                                        handleChangeAQATO_TeamMember(
                                          e,
                                          field?.param
                                        );
                                      }}

                                    className="form-select"
                                    // disabled={
                                    //   !isTeamMemberEditing[field?.param]
                                    // }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <option value="" disabled>
                                    Select Location
                                    </option>
                                    {field.options?.map((countryObj) =>
                                      Object.entries(countryObj).map(
                                        ([country, locations]) => (
                                          <optgroup
                                            key={country}
                                            label={country}
                                            style={{ fontWeight: "bold" }}
                                          >
                                            {locations.map((location) => (
                                              <option
                                                key={location.venue_id}
                                                value={location.venue_id}
                                                style={{ cursor: "pointer" }}
                                              >
                                                {location.venue_name}
                                              </option>
                                            ))}
                                          </optgroup>
                                        )
                                      )
                                    )}
                                  </select>
                                </Col>

                                <Col>
                                  {/* <button
                                    className="btn btn-sm btn_green_yellow"
                                    onClick={() =>
                                      toggleTeamMemberEditing(field?.param)
                                    }
                                  >
                                    <i
                                      className={
                                        isTeamMemberEditing[field?.param]
                                          ? "bi bi-check-lg"
                                          : "bi bi-pencil-square"
                                      }
                                    ></i>
                                  </button> */}
{/* 
{(teamMember[field?.param] !== field?.db_value &&
                isTeamMemberEditing[field?.param]) && (
                <button
                  className="btn btn-sm btn_green_yellow"
                  title="Save"
                  onClick={() => toggleTeamMemberEditing(field?.param)}
                >
                  <i className="bi bi-check-lg"></i>
                </button>
              )} */}

                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )}




{field?.type === "time_zone_select" &&
                          OnlineSelected === true && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>
                              <Col lg={8}>
                                    <div style={{ position: "relative" }}>
                                      {/* Clickable Select Box */}
                                      <div
                                        className="form-select md-4 cursor-pointer border border-gray-300 rounded p-2"
                                        onClick={() =>
                                          setShowDropdown(!showDropdown)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <span>
                                          {teamMember[field?.param] ||
                                            field?.db_value ||
                                            "Select Time Zone"}
                                        </span>
                                      </div>

                                      {/* Dropdown with Input Search */}
                                      {showDropdown && (
                                        <div
                                          className="position-absolute w-100 bg-white border border-gray-300 rounded shadow-lg"
                                          style={{
                                            maxHeight: "280px",
                                            overflowY: "auto",
                                            zIndex: 999, // Ensures dropdown is above other elements
                                            top: "100%", // Positions dropdown directly below the select box
                                            left: 0,
                                            right: 0,
                                          }}
                                        >
                                          {/* Search Input */}
                                          <input
                                            type="text"
                                            placeholder="Search timezones..."
                                            value={searchTerm}
                                            onChange={(e) =>
                                              setSearchTerm(e.target.value)
                                            }
                                            className="w-100 px-2 py-1 border-b border-gray-300"
                                            style={{
                                              borderColor: "green",
                                              borderRadius: "5px",
                                            }}
                                          />

                                          {/* Filtered Options */}
                                          {filteredOptions.length > 0 ? (
                                            filteredOptions.map((tmz) => (
                                              <div
                                                key={tmz.zone_name}
                                                className="dropdown-item py-2 px-3"
                                                onClick={() => {
                                                  handleChangeAQATO_TeamMember(
                                                    {
                                                      target: {
                                                        value: tmz.zone_name,
                                                      },
                                                    },
                                                    field?.param
                                                  );
                                                  setShowDropdown(false); // Close dropdown after selection
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                {tmz.zone_name}
                                              </div>
                                            ))
                                          ) : (
                                            <div className="dropdown-item text-muted py-2 px-3">
                                              No results found
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </Col>

                                <Col>
                                  {/* <button
                                    className="btn btn-sm btn_green_yellow"
                                    onClick={() =>
                                      toggleTeamMemberEditing(field?.param)
                                    }
                                  >
                                    <i
                                      className={
                                        isTeamMemberEditing[field?.param]
                                          ? "bi bi-check-lg"
                                          : "bi bi-pencil-square"
                                      }
                                    ></i>
                                  </button> */}

{/* {(teamMember[field?.param] !== field?.db_value &&
                isTeamMemberEditing[field?.param]) && (
                <button
                  className="btn btn-sm btn_green_yellow"
                  title="Save"
                  onClick={() => toggleTeamMemberEditing(field?.param)}
                >
                  <i className="bi bi-check-lg"></i>
                </button>
              )} */}

                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )}




                        {field.type === "textarea" && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>
                                <Col lg={8}>
                                  <textarea
                                    className="form-control"
                                    rows="3"
                                    value={
                                      BigTextAreaData[field?.param] ??
                                      field?.db_value 
                                    }
                                    onChange={(e) =>
                                      handleBigTextAreaDataChange(
                                        e,
                                        field?.param
                                      )
                                    }
                                    // disabled={
                                    //   !isBigTextAreaEditing[field?.param]
                                    // }
                                  />
                                </Col>

                                <Col lg={4} className="my-auto">
                                  {/* <button
                                    className="btn btn-sm btn_green_yellow"
                                    title={
                                      isBigTextAreaEditing[field?.param]
                                        ? "Save"
                                        : "Edit"
                                    }
                                    onClick={() =>
                                      toggleBigTextAreaDataEditing(field?.param)
                                    }
                                  >
                                    <i
                                      className={
                                        isBigTextAreaEditing[field?.param]
                                          ? "bi bi-check-lg"
                                          : "bi bi-pencil-square"
                                      }
                                    ></i>
                                  </button> */}



{/*                                   
              {(BigTextAreaData[field?.param] !== field?.db_value &&
                isBigTextAreaEditing[field?.param]) && (
                <button
                  className="btn btn-sm btn_green_yellow"
                  title="Save"
                  onClick={() => toggleBigTextAreaDataEditing(field?.param)}
                >
                  <i className="bi bi-check-lg"></i>
                </button>
              )} */}


                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )}



                     
{field?.type === "file" && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>
                                {(field?.db_value?.document_name !== "" &&
                                  field?.db_value?.document_fullpath !== "" && field?.db_value !== "" )&& (
                                    <div className="d-flex">
                                      <span style={{ cursor: "default" }}>
                                        Uploaded File:
                                      </span>
                                      <a
                                        href={
                                          field?.db_value?.document_fullpath
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          marginBottom: "10px",
                                          textDecoration: "none",
                                          cursor: "pointer",
                                          color: "blue",
                                          marginLeft: "5px",
                                        }}
                                        onMouseOver={(e) =>
                                          (e.currentTarget.style.textDecoration =
                                            "underline")
                                        }
                                        onMouseOut={(e) =>
                                          (e.currentTarget.style.textDecoration =
                                            "none")
                                        }
                                      >
                                        {field?.db_value?.document_name}
                                      </a>
                                    </div>
                                  )}

                                <Col lg={8} className="my-auto">
                                  <input
                                    type="file"
                                    accept=".pdf"
                                    className="form-control"
                                    style={{height:"35px"}}
                                    ref={(el) =>
                                      (fileInputRefs.current[field.param] = el)
                                    }
                                    onChange={(e) =>
                                      handleFileChange(e, field?.param)
                                    }

                                    disabled={
                                      (field?.db_value?.document_name === "" ||
                                      field?.db_value?.document_fullpath === "" ||
                                      field?.db_value === "") 
                                        ? false 
                                        : !isFileEditing[field?.param]
                                    }
                                    
                                    // disabled={!isFileEditing[field.param]}
                                  />
                                  {fileErrors[field?.param] && (
                                    <div className="text-danger">
                                      {fileErrors[field?.param]}
                                    </div>
                                  )}
                                </Col>
                                <Col lg={4} className="my-auto">
              {((field?.db_value?.document_name === "" ||
              field?.db_value?.document_fullpath === "" ||
              field?.db_value === "") && (!isFileChoosen[field?.param])) ? (
                ""
              ) : (
                <button
                  className="btn btn-sm btn_green_yellow"
                  onClick={() => toggleFileEditing(field?.param)}
                >
                  {(((field?.db_value?.document_name === "" ||
                  field?.db_value?.document_fullpath === "" ||
                  field?.db_value === "") && (isFileChoosen[field?.param]))) ? (
                    <i className="bi bi-check-lg"></i>
                  ) : (
                    <i
                      className={
                        isFileEditing[field?.param]
                          ? "bi bi-check-lg"
                          : "bi bi-pencil-square"
                      }
                    ></i>
                  )}
                </button>
              )}
            </Col>
                              </Row>
                            </td>
                          </tr>
                        )}




{field.type !== "file" &&
                          field.type !== "select" &&
                          field.type !== "textarea" &&
                          field.type !== "special_select" &&
                          field.type !== "time_zone_select" && (
                            <tr>
                              <td>{field?.label}</td>
                              <td className="w-25">
                                <Row>
                                  <Col lg={8}>
                                    <input
                                      type={field?.type}
                                      className="form-control"
                                      value={
                                        extraData[field?.param] ??
                                        field?.db_value
                                      }
                                      onChange={(e) =>
                                        handleExtraDataChange(e, field?.param)
                                      }
                                      // disabled={
                                      //   !isExtraDataEditing[field?.param]
                                      // }

                                      min={
                                        field?.label === "Closure Date" && field?.type === "date"
                                          ? field?.db_value || new Date().toISOString().split("T")[0]
                                          : null
                                      }
                                      max={
                                        field?.label === "Closure Date" && field?.type === "date"
                                          ? new Date(
                                              (field?.db_value
                                                ? new Date(field?.db_value)
                                                : new Date()
                                              ).getTime() + 7 * 24 * 60 * 60 * 1000
                                            )
                                              .toISOString()
                                              .split("T")[0]
                                          : null
                                      }

                                    />
                                  </Col>

                                  <Col lg={4} className="my-auto">
                                    {/* <button
                                      className="btn btn-sm btn_green_yellow"
                                      title={
                                        isExtraDataEditing[field?.param]
                                          ? "Save"
                                          : "Edit"
                                      }
                                      onClick={() =>
                                        toggleExtraDataEditing(field?.param)
                                      }
                                    >
                                      <i
                                        className={
                                          isExtraDataEditing[field?.param]
                                            ? "bi bi-check-lg"
                                            : "bi bi-pencil-square"
                                        }
                                      ></i>
                                    </button> */}



{/* {(extraData[field?.param] !== field?.db_value &&
                isExtraDataEditing[field?.param]) && (
                <button
                  className="btn btn-sm btn_green_yellow"
                  title="Save"
                  onClick={() => toggleExtraDataEditing(field?.param)}
                >
                  <i className="bi bi-check-lg"></i>
                </button>
              )} */}


                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          )}
                      </React.Fragment>
                    ))
                  : ""

                // <tr>
                //   <td colSpan="2">
                //     <b>No field is present for the selected status.</b>
                //   </td>
                // </tr>
              }































































              {
                currentFields.length > 0
                  ? currentFields.map((field) => (
                      <React.Fragment key={field?.param}>
                        {field?.type === "select" && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>
                                <Col lg={8}>
                                  <select
                                    value={
                                      teamMember[field?.param] ??
                                      field?.db_value 
                                    }
                                    onChange={(e) =>
                                      handleChangeAQATO_TeamMember(
                                        e,
                                        field?.param
                                      )
                                    }
                                    className="form-select"
                                    // disabled={
                                    //   !isTeamMemberEditing[field?.param]
                                    // }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <option value="" disabled>
                                      Select Team Member
                                    </option>
                                    {field.options?.map((member) => (
                                      <option
                                        key={member.id}
                                        value={member.id}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {member.fullname}
                                      </option>
                                    ))}
                                  </select>
                                </Col>

                                <Col>
                                  {/* <button
                                    className="btn btn-sm btn_green_yellow"
                                    onClick={() =>
                                      toggleTeamMemberEditing(field?.param)
                                    }
                                  >
                                    <i
                                      className={
                                        isTeamMemberEditing[field?.param]
                                          ? "bi bi-check-lg"
                                          : "bi bi-pencil-square"
                                      }
                                    ></i>
                                  </button> */}


                                  {/* {(teamMember[field?.param] !== field?.db_value &&
                isTeamMemberEditing[field?.param]) && (
                <button
                  className="btn btn-sm btn_green_yellow"
                  title="Save"
                  onClick={() => toggleTeamMemberEditing(field?.param)}
                >
                  <i className="bi bi-check-lg"></i>
                </button>
              )} */}

                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )}





{field?.type === "special_select" && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>
                                <Col lg={8}>
                                  <select
                                    value={
                                      teamMember[field?.param] ??
                                      field?.db_value 
                                    }
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        if (selectedValue === "9") {
                                          setOnlineSelected(true);
                                        } else {
                                          setOnlineSelected(false);
                                        }
  
                                        handleChangeAQATO_TeamMember(
                                          e,
                                          field?.param
                                        );
                                      }}

                                    className="form-select"
                                    // disabled={
                                    //   !isTeamMemberEditing[field?.param]
                                    // }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <option value="" disabled>
                                    Select Location
                                    </option>
                                    {field.options?.map((countryObj) =>
                                      Object.entries(countryObj).map(
                                        ([country, locations]) => (
                                          <optgroup
                                            key={country}
                                            label={country}
                                            style={{ fontWeight: "bold" }}
                                          >
                                            {locations.map((location) => (
                                              <option
                                                key={location.venue_id}
                                                value={location.venue_id}
                                                style={{ cursor: "pointer" }}
                                              >
                                                {location.venue_name}
                                              </option>
                                            ))}
                                          </optgroup>
                                        )
                                      )
                                    )}
                                  </select>
                                </Col>

                                <Col>
                                  {/* <button
                                    className="btn btn-sm btn_green_yellow"
                                    onClick={() =>
                                      toggleTeamMemberEditing(field?.param)
                                    }
                                  >
                                    <i
                                      className={
                                        isTeamMemberEditing[field?.param]
                                          ? "bi bi-check-lg"
                                          : "bi bi-pencil-square"
                                      }
                                    ></i>
                                  </button> */}
{/* 
{(teamMember[field?.param] !== field?.db_value &&
                isTeamMemberEditing[field?.param]) && (
                <button
                  className="btn btn-sm btn_green_yellow"
                  title="Save"
                  onClick={() => toggleTeamMemberEditing(field?.param)}
                >
                  <i className="bi bi-check-lg"></i>
                </button>
              )} */}

                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )}




{field?.type === "time_zone_select" &&
                          OnlineSelected === true && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>
                              <Col lg={8}>
                                    <div style={{ position: "relative" }}>
                                      {/* Clickable Select Box */}
                                      <div
                                        className="form-select md-4 cursor-pointer border border-gray-300 rounded p-2"
                                        onClick={() =>
                                          setShowDropdown(!showDropdown)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <span>
                                          {teamMember[field?.param] ||
                                            field?.db_value ||
                                            "Select Time Zone"}
                                        </span>
                                      </div>

                                      {/* Dropdown with Input Search */}
                                      {showDropdown && (
                                        <div
                                          className="position-absolute w-100 bg-white border border-gray-300 rounded shadow-lg"
                                          style={{
                                            maxHeight: "280px",
                                            overflowY: "auto",
                                            zIndex: 999, // Ensures dropdown is above other elements
                                            top: "100%", // Positions dropdown directly below the select box
                                            left: 0,
                                            right: 0,
                                          }}
                                        >
                                          {/* Search Input */}
                                          <input
                                            type="text"
                                            placeholder="Search timezones..."
                                            value={searchTerm}
                                            onChange={(e) =>
                                              setSearchTerm(e.target.value)
                                            }
                                            className="w-100 px-2 py-1 border-b border-gray-300"
                                            style={{
                                              borderColor: "green",
                                              borderRadius: "5px",
                                            }}
                                          />

                                          {/* Filtered Options */}
                                          {filteredOptions.length > 0 ? (
                                            filteredOptions.map((tmz) => (
                                              <div
                                                key={tmz.zone_name}
                                                className="dropdown-item py-2 px-3"
                                                onClick={() => {
                                                  handleChangeAQATO_TeamMember(
                                                    {
                                                      target: {
                                                        value: tmz.zone_name,
                                                      },
                                                    },
                                                    field?.param
                                                  );
                                                  setShowDropdown(false); // Close dropdown after selection
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                {tmz.zone_name}
                                              </div>
                                            ))
                                          ) : (
                                            <div className="dropdown-item text-muted py-2 px-3">
                                              No results found
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </Col>

                                <Col>
                                  {/* <button
                                    className="btn btn-sm btn_green_yellow"
                                    onClick={() =>
                                      toggleTeamMemberEditing(field?.param)
                                    }
                                  >
                                    <i
                                      className={
                                        isTeamMemberEditing[field?.param]
                                          ? "bi bi-check-lg"
                                          : "bi bi-pencil-square"
                                      }
                                    ></i>
                                  </button> */}
{/* 
{(teamMember[field?.param] !== field?.db_value &&
                isTeamMemberEditing[field?.param]) && (
                <button
                  className="btn btn-sm btn_green_yellow"
                  title="Save"
                  onClick={() => toggleTeamMemberEditing(field?.param)}
                >
                  <i className="bi bi-check-lg"></i>
                </button>
              )} */}

                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )}
                        

                        {field.type === "textarea" && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>
                                <Col lg={8}>
                                  <textarea
                                    className="form-control"
                                    rows="3"
                                    value={
                                      BigTextAreaData[field?.param] ??
                                      field?.db_value 
                                    }
                                    onChange={(e) =>
                                      handleBigTextAreaDataChange(
                                        e,
                                        field?.param
                                      )
                                    }
                                    // disabled={
                                    //   !isBigTextAreaEditing[field?.param]
                                    // }
                                  />
                                </Col>

                                <Col lg={4} className="my-auto">
                                  {/* <button
                                    className="btn btn-sm btn_green_yellow"
                                    title={
                                      isBigTextAreaEditing[field?.param]
                                        ? "Save"
                                        : "Edit"
                                    }
                                    onClick={() =>
                                      toggleBigTextAreaDataEditing(field?.param)
                                    }
                                  >
                                    <i
                                      className={
                                        isBigTextAreaEditing[field?.param]
                                          ? "bi bi-check-lg"
                                          : "bi bi-pencil-square"
                                      }
                                    ></i>
                                  </button> */}


              {/* {(BigTextAreaData[field?.param] !== field?.db_value &&
                isBigTextAreaEditing[field?.param]) && (
                <button
                  className="btn btn-sm btn_green_yellow"
                  title="Save"
                  onClick={() => toggleBigTextAreaDataEditing(field?.param)}
                >
                  <i className="bi bi-check-lg"></i>
                </button>
              )} */}

                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )}






                        {field?.type === "file" && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>
                                {(field?.db_value?.document_name !== "" &&
                                  field?.db_value?.document_fullpath !== "" && field?.db_value !== "" )&& (
                                    <div className="d-flex">
                                      <span style={{ cursor: "default" }}>
                                        Uploaded File:
                                      </span>
                                      <a
                                        href={
                                          field?.db_value?.document_fullpath
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          marginBottom: "10px",
                                          textDecoration: "none",
                                          cursor: "pointer",
                                          color: "blue",
                                          marginLeft: "5px",
                                        }}
                                        onMouseOver={(e) =>
                                          (e.currentTarget.style.textDecoration =
                                            "underline")
                                        }
                                        onMouseOut={(e) =>
                                          (e.currentTarget.style.textDecoration =
                                            "none")
                                        }
                                      >
                                        {field?.db_value?.document_name}
                                      </a>
                                    </div>
                                  )}

                                <Col lg={8} className="my-auto">
                                  <input
                                    type="file"
                                    accept=".pdf"
                                    className="form-control"
                                    style={{height:"35px"}}
                                    ref={(el) =>
                                      (fileInputRefs.current[field.param] = el)
                                    }
                                    onChange={(e) =>
                                      handleFileChange(e, field?.param)
                                    }

                                    disabled={
                                      (field?.db_value?.document_name === "" ||
                                      field?.db_value?.document_fullpath === "" ||
                                      field?.db_value === "") 
                                        ? false 
                                        : !isFileEditing[field?.param]
                                    }
                                    
                                    // disabled={!isFileEditing[field.param]}
                                  />
                                  {fileErrors[field?.param] && (
                                    <div className="text-danger">
                                      {fileErrors[field?.param]}
                                    </div>
                                  )}
                                </Col>
                                <Col lg={4} className="my-auto">
              {((field?.db_value?.document_name === "" ||
              field?.db_value?.document_fullpath === "" ||
              field?.db_value === "") && (!isFileChoosen[field?.param])) ? (
                ""
              ) : (
                <button
                  className="btn btn-sm btn_green_yellow"
                  onClick={() => toggleFileEditing(field?.param)}
                >
                  {(((field?.db_value?.document_name === "" ||
                  field?.db_value?.document_fullpath === "" ||
                  field?.db_value === "") && (isFileChoosen[field?.param]))) ? (
                    <i className="bi bi-check-lg"></i>
                  ) : (
                    <i
                      className={
                        isFileEditing[field?.param]
                          ? "bi bi-check-lg"
                          : "bi bi-pencil-square"
                      }
                    ></i>
                  )}
                </button>
              )}
            </Col>
                              </Row>
                            </td>
                          </tr>
                        )}





                              {field.type !== "file" &&
                          field.type !== "select" &&
                          field.type !== "textarea" &&
                          field.type !== "special_select" &&
                          field.type !== "time_zone_select" && (
                            <tr>
                              <td>{field?.label}</td>
                              <td className="w-25">
                                <Row>
                                  <Col lg={8}>
                                    <input
                                      type={field?.type}
                                      className="form-control"
                                      value={
                                        extraData[field?.param] ??
                                        field?.db_value 
                                      }
                                      onChange={(e) =>
                                        handleExtraDataChange(e, field?.param)
                                      }

                                      // disabled={
                                      //   !isExtraDataEditing[field?.param]
                                      // }

                                      min={
                                        field?.label === "Closure Date" && field?.type === "date"
                                          ? field?.db_value || new Date().toISOString().split("T")[0]
                                          : null
                                      }
                                      max={
                                        field?.label === "Closure Date" && field?.type === "date"
                                          ? new Date(
                                              (field?.db_value
                                                ? new Date(field?.db_value)
                                                : new Date()
                                              ).getTime() + 7 * 24 * 60 * 60 * 1000
                                            )
                                              .toISOString()
                                              .split("T")[0]
                                          : null
                                      }
                                    />
                                  </Col>

                                  <Col lg={4} className="my-auto">
                                    {/* <button
                                      className="btn btn-sm btn_green_yellow"
                                      title={
                                        isExtraDataEditing[field?.param]
                                          ? "Save"
                                          : "Edit"
                                      }
                                      onClick={() =>
                                        toggleExtraDataEditing(field?.param)
                                      }
                                    >
                                      <i
                                        className={
                                          isExtraDataEditing[field?.param]
                                            ? "bi bi-check-lg"
                                            : "bi bi-pencil-square"
                                        }
                                      ></i>
                                    </button> */}

{/*                     
              {(extraData[field?.param] !== field?.db_value &&
                isExtraDataEditing[field?.param]) && (
                <button
                  className="btn btn-sm btn_green_yellow"
                  title="Save"
                  onClick={() => toggleExtraDataEditing(field?.param)}
                >
                  <i className="bi bi-check-lg"></i>
                </button>
              )} */}

                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          )}

                      </React.Fragment>
                    ))
                  : ""

                // <tr>
                //   <td colSpan="2">
                //     <b>No field is present for the selected status.</b>
                //   </td>
                // </tr>
              }









              <tr>
                <td colSpan={2} className="text-center">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      type="submit"
                      className="btn btn_yellow_green"
                      // onClick={handleUpdate}
                      // disabled={!selectedStatusValue}
                      disabled={!isSelectionMade}
                      onClick={handleCancelRequestShow}
                    >
                      Update
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>

      {showCancelRequestModal && selectedStatusValue && isSelectionMade && (
        <VerificationModal
          show={showCancelRequestModal}
          onClose={handleCancelRequestClose}
          onYes={handleUpdate}
          Title="Confirm Update!"
          Message={
            <>
              {/* Are you sure you want to update status to{" "}
              <span style={{ fontWeight: "bold", color: "#055837" }}>
                {selectedStatusValue}
              </span>
              &nbsp;this ? */}
              Are you sure you want to change the status ?
            </>
          }
        />
      )}
    </>
  );
};

export default Stage_3_R;
