import React, { useState, useEffect } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Row } from "react-bootstrap";
import {
  getDataStaffManagementAPI,
  deleteForStaffManagment_Api,
  changeStatusForStaffManagment_Api,
} from "../../../api";

import { FaCaretDown } from "react-icons/fa";
import AddOccupationMoadal from "./AddOccupationModal/AddOccupationModal";
import EditOccupationMoadal from "./EditOccupationModal/EditOccupationModal";
import Loader from "../../Widgets/Loader/Loader";
import VerificationModal from "./VerificationModal/VerificationModal";

const AQATO = () => {

  const type = "aqato";

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const [dataPerPageButton, setDataPerPageButton] = useState("10");
  const handleDataPerPageChange = (event) => {
    setDataPerPageButton(event.target.value);
    setCurrentPage(1);
  };
  const [SelectedItem, setSelectedItem] = useState(null);

  const [showAddOccupationModal, setAddOccupationShow] = useState(false);
  const handlAddOccupationClose = () => setAddOccupationShow(false);
  const handlAddOccupationShow = () => setAddOccupationShow(true);

  const [showEditOccupationModal, setEditOccupationShow] = useState(false);
  const handlEditOccupationClose = () => setEditOccupationShow(false);
  const handleEditOccupationShow = (item) => {
    setSelectedItem(item);
    setEditOccupationShow(true);
    setChangeStatusModalShow(false);
    setDeleteAccountShow(false);
    setAddOccupationShow(false);
  };

  const [showDeleteAccountModal, setDeleteAccountShow] = useState(false);
  const handleDeleteAccountClose = () => setDeleteAccountShow(false);
  const handleDeleteAccountShow = (item) => {
    setSelectedItem(item);
    setDeleteAccountShow(true);
    setChangeStatusModalShow(false);
    setEditOccupationShow(false);
    setAddOccupationShow(false);
  };

  const [showChangeStatusModal, setChangeStatusModalShow] = useState(false);
  const handleChangeStatusModalClose = () => setChangeStatusModalShow(false);
  const handleChangeStatusModalShow = (item) => {
    setSelectedItem(item);
    setChangeStatusModalShow(true);
    setDeleteAccountShow(false);
    setEditOccupationShow(false);
    setAddOccupationShow(false);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const [currentPageData, setCurrentPageData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const getOccupationsFunction = async () => {
    try {
      setLoading(true);

      const response = await getDataStaffManagementAPI(type);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {

        const staffs_data = response?.data?.response?.data?.staffs;
        const get_Countries = response?.data?.response?.data?.countries;

        setCountries(get_Countries);
        setOriginalData(staffs_data);
        setCurrentPageData(staffs_data.slice(0, parseInt(dataPerPageButton)));

      } else {
        setLoading(false);
        console.error(
          "Error fetching data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getOccupationsFunction();
  }, []);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(originalData.length / dataPerPageButton)) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const indexOfLastItem = currentPage * dataPerPageButton;
    const indexOfFirstItem = indexOfLastItem - dataPerPageButton;
    const currentItems = originalData.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentPageData(currentItems);
  }, [currentPage, dataPerPageButton, originalData]);

  useEffect(() => {
    if (searchQuery === "") {
      setCurrentPageData(originalData.slice(0, parseInt(dataPerPageButton)));
    } else {
      const filteredData = originalData.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
      setCurrentPageData(filteredData.slice(0, parseInt(dataPerPageButton)));
    }
  }, [searchQuery, dataPerPageButton, originalData]);

  const handleDeleteYes = async (SelectedItem) => {
    try {
      setLoading(true);

      const response = await deleteForStaffManagment_Api(SelectedItem?.id);

      setLoading(false);

      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.response === true
      ) {
        toast.success(
          response.data.response.success_msg ||
          "Staff Deleted Successfully."
        );
        getOccupationsFunction();
        handleDeleteAccountClose();
        setSelectedItem(null);
      } else {
        toast.error(response.data.error_msg || "Error in deleting staff.");
      }
    } catch (error) {
      setLoading(false);
      toast.error("An unexpected error occurred.");
    }
  };

  const handleChangeStatus = async (SelectedItem) => {
    try {
      setLoading(true);

      const response = await changeStatusForStaffManagment_Api(SelectedItem?.id);

      setLoading(false);

      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.response === true
      ) {
        toast.success(
          response.data.response.success_msg ||
          "Staff Status Changed successfully."
        );
        getOccupationsFunction();
        handleChangeStatusModalClose();
        setSelectedItem(null);
      } else {
        toast.error(
          response.data.error_msg || "Error changing status of staff."
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error("An unexpected error occurred.");
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      {loading && <Loader />}

      <div className="main-body-area mb-5">
        <Card className="shadow">
          <div className="mt-b">
            <div className="action_buttons_top bg-white action_btn_desk">
              <Row className="my-2 mx-2 align-items-center">
                <Col lg="1" className="my-auto">
                  <div style={{ position: 'relative', display: 'inline-block', width: '60px' }}>
                    <select
                      value={dataPerPageButton}
                      onChange={handleDataPerPageChange}
                      style={{
                        width: '100%',
                        padding: '6px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                        appearance: 'none', // Remove default arrow
                        WebkitAppearance: 'none', // Safari compatibility
                        MozAppearance: 'none', // Firefox compatibility
                        backgroundColor: 'white',
                        cursor: 'pointer'
                      }}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    <FaCaretDown
                      style={{
                        position: 'absolute',
                        top: '50%',
                        right: '5px',
                        transform: 'translateY(-50%)',
                        pointerEvents: 'none', // Prevent interaction with the icon
                        fontSize: '14px',
                        color: '#333'
                      }}
                    />
                  </div>

                </Col>
                <Col lg="9">
                  <Row className="justify-content-center align-items-center search-btn">
                    <Col lg="12">
                      <Form.Control
                        type="search"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={handleSearch}
                        className="searchInput"
                      />
                    </Col>
                  </Row>
                </Col>

                <Col lg="2" className="my-auto text-end d-flex plus-btn">
                  <button
                    data-bs-toggle="modal"
                    className="btn btn-sm btn_green_yellow ms-1"
                    onClick={handlAddOccupationShow}
                    style={{ padding: "8px" }}
                  >
                    <i className="bi bi-plus"></i>
                    Add AQATO Staff
                  </button>
                </Col>
              </Row>
            </div>

            <div id="table_wrapper" className="dataTables_wrapper no-footer">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12">
                    <div class="table-responsive table-wrapper">
                      <table
                        class="table table-striped table-hover dataTable no-footer"
                        style={{ cursor: "default" }}
                      >
                        <thead>
                          <tr>
                            <th className="col-1 th_font_size">Sr.No.</th>
                            <th className="col-3 th_font_size">
                              Full Name
                            </th>
                            <th className="col-5 th_font_size">	Email Id</th>
                            <th className="col-1  th_font_size">Status</th>
                            <th className="col-1 th_font_size">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {currentPageData.map((item, index) => (
                            <tr
                              key={index}
                              className={index % 2 === 0 ? "even" : "odd"}
                            >
                              <td className="td_font_size">{index + 1}.</td>
                              <td className="td_font_size">   {item?.fullname}</td>
                              <td>     {item?.email}</td>
                              <td className="td_font_size">
                                <button
                                  className={`btn btn-sm ms-1 ${item.status === "inactive"
                                      ? "btn-danger"
                                      : "btn_green_yellow"
                                    }`}
                                  style={{
                                    width: "85px",
                                    height: "35px",
                                  }}
                                  onClick={() =>
                                    handleChangeStatusModalShow(item)
                                  }
                                >
                                  <strong>{item?.status?.toUpperCase()}</strong>
                                </button>
                              </td>

                              <td className="btn-flex">
                                <button
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit_form1"
                                  className="btn btn-sm btn_green_yellow ms-1"
                                  onClick={() => handleEditOccupationShow(item)}
                                >
                                  <i className="bi bi-pencil-square"></i>
                                </button>
                                &nbsp;
                                <button
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_req2"
                                  className="btn btn-sm btn-danger ms-1"
                                  onClick={() => handleDeleteAccountShow(item)}
                                // disabled={true}
                                >
                                  <i className="bi bi-trash-fill"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="thick-hr" />

              <div className="pagination-container p-3">
                <div className="dataTables_info">
                  Showing{" "}
                  {currentPage === 1
                    ? 1
                    : (currentPage - 1) * dataPerPageButton + 1}{" "}
                  to{" "}
                  {Math.min(
                    currentPage * dataPerPageButton,
                    originalData.length
                  )}{" "}
                  of {originalData.length} entries
                </div>

                <div className="pagination-buttons">
                  <button
                    className={`paginate_button ${currentPage === 1 ? "disabled" : ""
                      }`}
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                    style={
                      currentPage === 1
                        ? {
                          pointerEvents: "none",
                          cursor: "not-allowed",
                        }
                        : {}
                    }
                  >
                    Previous
                  </button>

                  <button className="paginate_button">{currentPage}</button>

                  <button
                    className={`paginate_button ${currentPage ===
                        Math.ceil(originalData.length / dataPerPageButton)
                        ? "disabled"
                        : ""
                      }`}
                    onClick={handleNext}
                    disabled={
                      currentPage ===
                      Math.ceil(originalData.length / dataPerPageButton)
                    }
                    style={
                      currentPage ===
                        Math.ceil(originalData.length / dataPerPageButton)
                        ? {
                          pointerEvents: "none",
                          cursor: "not-allowed",
                        }
                        : {}
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>

      {showAddOccupationModal && (
        <AddOccupationMoadal
          showModal={showAddOccupationModal}
          handleClose={handlAddOccupationClose}
          getOccupationsFunction={getOccupationsFunction}
          countries={countries}
          type={type}
        />
      )}

      {showEditOccupationModal && SelectedItem && (
        <EditOccupationMoadal
          showModal={showEditOccupationModal}
          handleClose={() => handlEditOccupationClose && setSelectedItem(null)}
          SelectedItem={SelectedItem}
          getOccupationsFunction={getOccupationsFunction}
          countries={countries}
          type={type}

        />
      )}

      {showDeleteAccountModal && SelectedItem && (
        <VerificationModal
          show={showDeleteAccountModal}
          onClose={() => handleDeleteAccountClose && setSelectedItem(null)}
          onYes={() => handleDeleteYes(SelectedItem)}
          Title="Confirm Delete!"
          Message={
            <>
              Are you sure you want to delete{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>
                &nbsp; {SelectedItem?.fullname}
              </span>
              &nbsp; Staff ?
            </>
          }
        />
      )}

      {showChangeStatusModal && SelectedItem && (
        <VerificationModal
          show={showChangeStatusModal}
          onClose={() => handleChangeStatusModalClose && setSelectedItem(null)}
          onYes={() => handleChangeStatus(SelectedItem)}
          Title="Confirm Change Status !"
          Message={
            <>
              Are you sure you want to change{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: SelectedItem?.status === "active" ? " #055837" : "red",
                }}
              >
                &nbsp; {capitalizeFirstLetter(SelectedItem?.status)}
              </span>
              &nbsp; to{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: SelectedItem?.status === "active" ? "red" : " #055837",
                }}
              >
                &nbsp;{" "}
                {capitalizeFirstLetter(
                  SelectedItem?.status === "active" ? "inactive" : "active"
                )}
              </span>
              &nbsp; of <strong>{SelectedItem?.fullname}</strong> Staff ?
            </>
          }
        />
      )}
    </>
  );
};

export default AQATO;
